import { useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";

import Member from "./Member";
import Unit from "./Unit";

import { useDataApi, Pending } from "../Data";
import WithSidebar from "../components/Sidebar";
import View from "../components/View";
import { MessageBox, ErrorBox } from "../components/Utils";


function UnitList() {
  let [ uDispatch, uReset, { data: uData, error: uError, isLoading: uLoading } ] = useDataApi();
  let [ mDispatch, mReset, { data: mData, error: mError, isLoading: mLoading } ] = useDataApi();

  let { unitId } = useParams();

  useEffect(() => {
    let canceled = false;
    if (!canceled) uDispatch('/units');
    if (!canceled && !!unitId) mDispatch(`/units/${unitId}`);
    return () => {canceled = true};
  }, [ unitId ]);

  if (uLoading || mLoading) return <Pending />;
  if (!!uError) return <ErrorBox error={uError} />;
  if (!!mError) return <ErrorBox error={mError} />;

  if (!uData?.units) return <MessageBox>Not Found</MessageBox>;

  // Redirect to the first available unit of the account
  if (!unitId) return <Navigate to={Unit.asLink(uData.units[0]).to} replace={true} />;

  if (!mData) return <View.NotFound />;

  return (
    <WithSidebar
      series={uData.units.map(Unit.asLink)}
      >
      <Unit {...mData}/>
    </WithSidebar>
  );
}


function MemberView() {
  let [ dispatch, resetDispatch, { data, error, isLoading } ] = useDataApi();

  let { unitId, memberId } = useParams();

  useEffect(() => {
    let canceled = false;
    if (!canceled && !!unitId) {
      dispatch(`/units/${unitId}`);
    }
    return () => {canceled = true};
  }, [ memberId ]);

  if (isLoading) return <Pending />;
  if (!!error) return <ErrorBox error={error} />;

  if (!data?.members) return <MessageBox>Not Found</MessageBox>;

  let param;
  // On creating
  if (!memberId) {
    param = {};
  } else {
    param = data.members.filter(e => e._id === memberId)[0];
    if (!param) return <View.NotFound />;
  }

  return (
    <WithSidebar
      title={data.name}
      series={data.members.map(e => Member.asLink({unitId, ...e}))}
      >
      <Member unitId={unitId} {...param} />
    </WithSidebar>
  );
}


export default Object.assign({}, { Unit: UnitList, Member: MemberView });
