import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import View from "./components/View";

import App from "./App";
import LandingView from "./Landing";
import AuthView from "./Login";
import UserView from "./User";
import BookView from "./Book";

import { Pending } from "./Data";

import "bootstrap/dist/css/bootstrap.min.css";


const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <React.StrictMode>
  <BrowserRouter>

    <Suspense fallback={<Pending />}>
      <Routes>

        <Route path="/login"        element={<App />}>
          <Route index              element={<AuthView.Login />} />
        </Route>

        <Route path="/"             element={<App.Protected />}>
          <Route index              element={<LandingView />} />

          <Route path="settings"    element={<UserView.User />}>
            <Route index            element={<Navigate to="reset" replace />} />
            <Route path="reset"     element={<UserView.PasswordEditor />} />
          </Route>
          <Route path="logout"      element={<AuthView.Logout />} />

          <Route path="book">
            <Route index                                   element={<BookView.Unit />} />
            <Route path="units/:unitId"                    element={<BookView.Unit />} />
            <Route path="units/:unitId/members"            element={<BookView.Member />} />
            <Route path="units/:unitId/members/:memberId"  element={<BookView.Member />} />
          </Route>
        </Route>

        <Route element={<View.NotFound />} />
      </Routes>
    </Suspense>

  </BrowserRouter>
  </React.StrictMode>
);
