import { useState } from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { NavLink, Link, Outlet } from "react-router-dom";

import View from "./components/View";
import WithSidebar from "./components/Sidebar";
import { useApi, Pending } from "./Data";
import { MessageBox, ErrorBox } from "./components/Utils";


function User({ user }) {
  let links = [
    { to: `/settings/reset`, name: '重設密碼' },
  ]
  return <WithSidebar
    series={links}
    >
    <Outlet />
  </WithSidebar>
}


function Instruction({ user }) {
  return <View
    title="帳號管理"
  ></View>
}


function PasswordEditor({ user }) {
  let [ dispatch, resetDispatch, { data, error, isLoading } ] = useApi();
  let [ formError, setError ] = useState(null);
  let [ fetched, setFetched ] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setError(null);

    let formData = new FormData(e.target);
    if ((!formData.get('password')) || (!formData.get('forconfirm'))) {
      setError('有欄位尚未填寫');
      return;
    }
    if (formData.get('password') !== formData.get('forconfirm')) {
      setError('密碼不一致，請重新確認');
      return;
    }
    let post = {
      'password': formData.get('password'),
    }
    async function put() {
      await dispatch(`/oauth/me`, 'PUT', post);
      setFetched(true);
    }
    put();
  }

  if (isLoading) return <Pending />;

  let messageSuccess = (
    <MessageBox variant="success">
      <span>完成更新</span>
      {' '}
      <Link to="/logout">重新登入</Link>
    </MessageBox>
  );

  return (
    <>
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formPassword">
        <Form.Label>新密碼</Form.Label>
        <Form.Control type="password" placeholder="輸入密碼" name="password" required/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formPasswordConfirm">
        <Form.Label>再次輸入新密碼</Form.Label>
        <Form.Control type="password" placeholder="輸入密碼" name="forconfirm" required/>
      </Form.Group>
      <Button variant="primary" type="submit">
        送出
      </Button>
    </Form>
    {(fetched && !(formError || error)) ? messageSuccess : <></>}
    <MessageBox>{(formError || error?.message)}</MessageBox>
    </>
  );
}


function ProfileEditor(props) {
  function handleSubmit(e) {
    e.preventDefault();
  }
  return (
    <>
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formPassword">
        <Form.Label>新密碼</Form.Label>
        <Form.Control type="password" placeholder="輸入密碼" name="password" required/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formPasswordConfirm">
        <Form.Label>再次輸入新密碼</Form.Label>
        <Form.Control type="password" placeholder="輸入密碼" name="forconfirm" required/>
      </Form.Group>
      <Button variant="primary" type="submit">
        送出
      </Button>
    </Form>
    </>
  );
}

export default {
  User,
  PasswordEditor,
  ProfileEditor,
  Instruction,
}
