import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";

import { NavLink } from "react-router-dom";

import { IconSvg } from "../Utils";


const Icon = Object.freeze({
  caret: {
    expanded: (
      <IconSvg identifier="caret-down">
        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
      </IconSvg>
    ),
    collapsed: (
      <IconSvg identifier="caret-right">
        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
      </IconSvg>
    ),
  },
  chevron: {
    expanded: (
      <IconSvg identifier="chevron-down">
        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
      </IconSvg>
    ),
    collapsed: (
      <IconSvg identifier="chevron-right">
        <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
      </IconSvg>
    ),
  },
});


function Item({ to, name }) {
  return (
    <Nav.Item>
      <Nav.Link as={NavLink} to={to}>{name}</Nav.Link>
    </Nav.Item>
  );
}


function Sidebar({ series, title }) {
  return (
    <>
      {(!!title) ? <h6 className="mb-3">{title}</h6> : <></>}
      <Nav
        variant="pills"
        className="sidebar flex-column"
        >
        {series.map(e => <Item {...e} key={e.to} />)}
      </Nav>
    </>
  );
}


function WithSidebar({ children, series, title }){
  return (
    <Row>
      <Col
        className="bg-light pt-3"
        xs={5}
        sm={4}
        md={3}
        >
        <Sidebar
          title={title}
          series={series}
          />
      </Col>
      <Col className="m-3">
        {children}
      </Col>
    </Row>
  );
}


export default WithSidebar;
