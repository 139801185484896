import { useState, useEffect, Suspense, useContext } from "react";
import { NavLink, Link, Outlet } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/NavBar";

import { Navigate } from "react-router-dom";

import { fetchApi, Pending } from "./Data";
import { UserContext, useToken, IconSvg } from "./Utils";
import { ErrorBox } from "./components/Utils";


// Source: https://icons.getbootstrap.com/
const Icon = Object.freeze({
  home: (
    <IconSvg identifier="house">
      <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z" />
    </IconSvg>
  ),
  book: (
    <IconSvg identifier="journal-bookmark-fill">
      <path fillRule="evenodd" d="M6 1h6v7a.5.5 0 0 1-.757.429L9 7.083 6.757 8.43A.5.5 0 0 1 6 8V1z"/>
      <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"/>
      <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"/>
    </IconSvg>
  ),
  profile: (
    <IconSvg identifier="person-circle">
      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
      <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
    </IconSvg>
  ),
})


function UserNavbar({ user }) {
  if (!user) return <></>;

  return (<>
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav variant="underline">
        <Nav.Link as={NavLink} to="/">{Icon.home} 首頁</Nav.Link>
        <Nav.Link as={NavLink} to="/book">{Icon.book} 通訊錄</Nav.Link>
      </Nav>
    </Navbar.Collapse>
    <Navbar.Collapse className="justify-content-end">
      <Nav>
        <Dropdown as={Nav.Item} style={{minWidth: '10em', textAlign: 'right'}}>
          <Dropdown.Toggle as={Nav.Link}>{Icon.profile} {user.profile?.name}</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to="/settings" >帳號管理</Dropdown.Item>
            <Dropdown.Item as={Link} to="/logout" >登出</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav>
    </Navbar.Collapse>
  </>);
}


function App({ error }) {
  let user = useContext(UserContext);

  return (<>
    <Navbar expand="md" bg="dark" data-bs-theme="dark" sticky="top">
      <Container fluid>
        <Navbar.Brand href="/">經濟部水利署災防通訊錄</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <UserNavbar user={user}/>
      </Container>
    </Navbar>
    <Container fluid as="main">
      <Suspense fallback={<Pending />}>
        <Outlet />
      </Suspense>
      <ErrorBox error={error} />
    </Container>
  </>);
}


function Protected() {
  let [ token, setToken ] = useToken();

  let [ user, setUser ] = useState(null);
  let [ error, setError ] = useState(null);

  useEffect(() => {
    if (!token) return;
    let canceled = false;

    async function dispatch() {
      try {
        let r = await fetchApi(token, '/oauth/me');
        if (!canceled) setUser(r)
      } catch (err) {
        if (!canceled) setError(err);
      }
    }

    dispatch();
    return () => {canceled = true};
  }, []);

  if (!token) return <Navigate to='/login' />;

  if (error?.cause === 401) {
    setToken(); // cleanup invalid token
    return <Navigate to='/login' />
  }

  return (
    <UserContext.Provider value={user}>
      <App error={error} />
    </UserContext.Provider>
  );
}

export default Object.assign(App, { Protected });
