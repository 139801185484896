import { useState } from "react";

import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";

import { Link } from "react-router-dom";

import { useDataApi, Pending } from "../Data";
import { IconSvg } from "../Utils";
import { ErrorBox } from "../components/Utils";


const Icon = Object.freeze({
  grip: (
    <IconSvg identifier="grip-horizontal">
      <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
    </IconSvg>
  ),
  open: (
    <IconSvg identifier="chevron-right">
      <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
    </IconSvg>
  ),
  add: (
    <IconSvg identifier="plus-circle">
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
    </IconSvg>
  )
});


function Tag({ children, ...rest }) {
  return (<Badge style={{marginLeft: '2em'}} {...rest}>{children}</Badge>);
}

function useOrderControls(members) {
  let [ curDrag, setDrag ] = useState(null);
  let [ curOrder, setOrder ] = useState(members.map(e => e._id));

  let compareOrder = (a, b) => curOrder.indexOf(a._id) - curOrder.indexOf(b._id);

  return { curDrag, setDrag, curOrder, setOrder, compareOrder };
}


function ContactLines({ type, value }) {
  return <li>({type.code}) {value}</li>;
}


function Member({ _id, name, position, for_emergency, for_unit, contacts, icon, ...rest }) {
  return (
    <Card
      id={_id}
      className="member"
      {...rest}
      >
      <Card.Body>
        <Stack direction="horizontal">
          <div className="me-auto">
            <span className="name">{name}</span>
            <span className="position">{position}</span>
            <ul>
              { contacts?.map((e, i) => <ContactLines {...e} key={i}/>) }
            </ul>
            {
              (for_emergency) ?
                <Tag bg="danger">應變聯絡人</Tag> : <></>
            }
            {
              (for_unit) ?
                <Tag bg="info">單位/辦公室傳真</Tag> : <></>
            }
          </div>
          {icon}
        </Stack>
      </Card.Body>
    </Card>
  );
}


function Grip({ unitId, orderControls, ...rest }) {
  const { _id: memberId } = rest;
  let { curDrag, setDrag, curOrder, setOrder } = orderControls;

  function handleDragOver(e) {
    e.preventDefault();

    if (curDrag === memberId) return;

    let updatedOrder = [...curOrder];
    let source = curOrder.indexOf(curDrag);
    let anchor = curOrder.indexOf(memberId);

    updatedOrder[source] = memberId;
    updatedOrder[anchor] = curDrag;

    setOrder(updatedOrder);
  }

  let isDragging = curDrag === memberId;

  return (
    <Member
      draggable
      onDragStart={() => setDrag(memberId)}
      onDragEnd={() => setDrag(null)}
      onDragOver={handleDragOver}
      border={(isDragging) ? 'primary' : undefined}
      icon={Icon.grip}
      {...rest}
      />
  );
}


function Tap({ orderControls, unitId, ...props }) {
  return (
    <Member
      {...props}
      icon={Icon.open}
      as={Link}
      to={`/book/units/${unitId}/members/${props._id}`}
      />
    );
}


function TapCreate({ unitId }) {
  return (
    <Member
      name="新增人員"
      icon={Icon.add}
      border="primary"
      as={Link}
      to={`/book/units/${unitId}/members`}
      />
    );
}


const UnitState = Object.freeze({
  view: 'view',
  edit: 'edit',
  order: 'order',
});


function UnitAction({ children, ...rest }) {
  return <Button size="sm" {...rest}>{children}</Button>
}


function useUnitState(initState = UnitState.view) {
  let [ state, setState ] = useState(initState);

  let isEditing = state === UnitState.edit;
  let isOrdering = state === UnitState.order;

  function setUnitState(value = undefined) {
    if ((!value) || (value === UnitState.view) || (value === UnitState.order)) {
      setState((!!value) ? value : UnitState.view);
    } else {
      setState(UnitState.edit);
    }
  }

  return [ isEditing, isOrdering, setUnitState ];
}


function Unit({ _id: unitId, name, members }) {
  let [ isEditing, isOrdering, setUnitState ] = useUnitState();
  let [ dispatch, resetDispatch, { data, error, isLoading } ] = useDataApi();

  let orderControls = useOrderControls(members);
  let { curOrder, compareOrder, setOrder } = orderControls;

  members = members.map(e => Object.assign(e, { unitId, orderControls }));

  function handleCommit() {
    async function put() {
      await dispatch(`/units/${unitId}/commit`, 'PUT', {});
    }
    put();
    window.alert('完成確認資料');
  }

  function handleSave() {
    async function put() {
      await dispatch(`/units/${unitId}/members`, 'PUT', {'members': curOrder});
    }
    put();
  }

  function handleReset() {
    resetDispatch();
    setUnitState(); // reset
    setOrder(members.map(e => e._id));
  }

  let body;
  let sorted = [...members].sort(compareOrder);
  if (isLoading) body = <Pending />;
  else if (isOrdering) {
    body = <div className="mb-3">{sorted.map(e => <Grip {...e} key={e._id} />)}</div>;
  } else {
    body = <div className="mb-3">
      {sorted.map(e => <Tap {...e} key={e._id} />)}
      <TapCreate unitId={unitId} />
    </div>;
  }

  let utils;
  if (isOrdering) {
    utils = (<>
      <UnitAction key="reset" onClick={handleReset} variant="secondary">取消</UnitAction>
      <UnitAction key="save" onClick={handleSave}>儲存</UnitAction>
    </>);
  } else if ((isEditing) || (members.length === 0)) {
    utils = <></>;
  } else {
    utils = (<>
      <UnitAction onClick={handleCommit} key="commit">確認資料</UnitAction>
      <UnitAction as={Link} to={`/book/units/${unitId}/members`} key="create">新增人員</UnitAction>
      <UnitAction onClick={() => setUnitState(UnitState.order)} key="order">修改順序</UnitAction>
    </>);
  }

  if ((!!data) && isOrdering) {
    setUnitState();
    resetDispatch();
  }

  return (<>
    <Stack as="h2" direction="horizontal" gap={2}>
      <span className="me-auto">{ name }</span>
      { utils }
    </Stack>
    { body }
    <ErrorBox error={error} />
  </>);
}


function asLink({ _id, name }) {
  return { to: `/book/units/${_id}`, name: name };
}


export default Object.assign(Unit, { asLink, Grip, Tap });
